import React from 'react';
import image2 from "../../static/image_2.png"
import pdf from "../../static/pdf.png"


const LeftPannelFiles = () => {
    return (
        <div className='left-pannel-files-container'>
            {/* <div className='left-pannel-files-row'>
                <img src='./product_images/input-image.png' className='left-pannel-files-image'/>
                <p className='left-pannel-files-filename'>ElectricTropix_Packaging.png</p>
            </div> */}
            <div className='left-pannel-files-row'>
                <img src={pdf} className='left-pannel-files-image'/>
                <p className='left-pannel-files-filename'>Schwechat Refinery Brief.pdf</p>
            </div>

        </div>
    );
};

export default LeftPannelFiles;