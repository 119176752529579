import React, { useEffect, useState } from "react";
import './App.css';
import AgentsChat from './Components/AgentsChat/AgentsChat';
import Chat from './Components/Chat/Chat';
import LeftPannel from './Components/LeftPannel/LeftPannel';
import Summary from "./Components/Summary/Summary";
import companyLogo from './static/omv-logo-header.svg';




function App() {

  const [leftPannelContent, setLeftPannelContent] = useState(["No info provided yet", false, false])

  function refreshPage() {
    window.location.reload(false);
  }


  return (
    <>
      <div className="omv-header">
      <button className="omv-logo" onClick={refreshPage}><img src={companyLogo} /></button><div className='demo-headline'>Agent Orchestra Demo</div>
        {/*<div className="omv-logo"><img src={companyLogo} /></div>  */}
      </div>
      <div className='main-container'>
        <LeftPannel leftPannelContent={leftPannelContent} />
        <Chat leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} />
      </div>
      

    </>

  );
}

export default App;
