import React, { useEffect, useState } from 'react';
import Agent from './Agent';
import LoadingDots from '../Loading/LoadingDots';


const Agents = ({ leftPannelContent, setLeftPannelContent, setIsLoading, isLoading}) => {

    const [waitOver, setWaitOver] = useState(false)
    const [waitOver2, setWaitOver2] = useState(false)
    const [waitOver3, setWaitOver3] = useState(false)
    const [waitOver4, setWaitOver4] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            setWaitOver(true)
        
        }, 2000)

        setTimeout(() => {
            setIsLoading(false)
            setWaitOver2(true)
            document.getElementById('messages-container').lastChild.scrollIntoView({ block: "end" })
        
        }, 3000)
        setTimeout(() => {
            setIsLoading(false)
            setWaitOver3(true)
            document.getElementById('messages-container').lastChild.scrollIntoView({ block: "end" })
        
        }, 4000)

        setTimeout(() => {
            setIsLoading(false)
            setWaitOver4(true)
            document.getElementById('messages-container').lastChild.scrollIntoView({ block: "end" })
        
        }, 5000)

    }, [])


    const handleButtonClick = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            setLeftPannelContent((leftPannelContent) =>
                [leftPannelContent[0], leftPannelContent[1], true]
    
            )
        }, 2000)



    };

    return (
        <>
        
            {waitOver && (
                <div id="progressive-loading-container">
                    <p className='message' id='message-1'>Thanks for sharing. Here is my suggested team to tackle this project.</p>
                    <div className='proposed-agents-container'>
                        {waitOver2 &&(
                            <>
                        <div className='proposed-agents-row'>
                            <Agent agentName="EcoStrategist" />
                            <Agent agentName="ProjectMaster" />
                            <Agent agentName="OpsPro" />
                            
                        </div>
         
                        <div className='proposed-agents-row'>
                            <Agent agentName="AssetGuardian" />
                            <Agent agentName="SafetyShield" />
                            <Agent agentName="Surfer" />
                            
                        </div>
                        </>
                        )}
                        {waitOver3 &&(
                            
                        <p className='message'>And you might also consider inviting  </p>
                    )}
                        {waitOver4 &&(
                        <div className='proposed-agents-row'>
                            <div onClick={handleButtonClick}>
                                <Agent agentName="FinWizard" />
                            </div>
                            {/* <Agent agentName="Cooky" /> */}
                            <div className='add-another-agent'>+</div>
                        </div>
                        
                        )}

                    </div>
                </div>
            )}
            
        </>
    );
};

export default Agents;