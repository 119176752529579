import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import MyDocument from "./ReportPDF";
import ReportPDF from "./ReportPDF";

function Summary() {

    const [documentPath, setDocumentPath] = useState("")
    const [waitOver, setWaitOver] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setWaitOver(true)
            
        
        }, 2000)
    },[])

    useEffect(() => {
        if(document.getElementById("summary-card")){
        document.getElementById("summary-card").scrollIntoView({block: "start"})
    }

    }, [waitOver])



    const downloadReport = () => {
        setDocumentPath("./generated_documents/report.pdf")
    }

    const downloadTranscript = () => {
        setDocumentPath("./generated_documents/transcript.pdf")

    }

    return (
        <>
            <div className="summary-container" id="summary-container">
                <div className="manager-message">
                    <div className="manager-message-content">
                        <p>Done! Here is a summary of the team’s work.</p>
                    </div>
                </div>

                {waitOver && (

                <div className="summary-card" id = "summary-card">
                    <div className="summary-card-header">Conclusion</div>
                    <div className="summary-card-content">
                        <p>
                        The OMV Schwechat refinery expansion project aims to integrate innovative co-processing technology to produce sustainable fuels, aligning with OMV's strategic goals of reducing carbon emissions and achieving net-zero emissions by 2050.
                        <br/><br/>
                        <b>Strategic Overview:</b> The project aligns with OMV's vision for sustainable fuels, setting targets for carbon emission reduction and promoting circular economy principles.
                        </p>
                        <p>
                        <b>Project Management:</b> A detailed project timeline with milestones, resource allocation, and risk assessments are essential for the project's success.
                        </p>

                        <p>
                        <b>Operational Efficiency:</b> Optimizing the integration of co-processing technology, establishing a reliable supply chain for biogenic feedstocks, and implementing staff training are key operational goals.
                        </p>
                        <p>
                        <b>Maintenance and Asset Management:</b> A maintenance plan for new assets, performance monitoring systems, and asset lifecycle management are crucial for sustained operations.
                        </p>
                        <p>
                        <b>Health, Safety, Security, and Environment:</b> Safety protocols, environmental impact assessments, and an emergency response plan are vital for HSSE compliance.
                        </p>
                        <p>
                        <b>Financial Planning:</b> A detailed budget, cost-benefit analysis, and identification of funding sources are necessary for financial viability.
                        </p>
                        <p>
                        <b>Communication and Reporting:</b> Setting up internal communication channels and external reporting frameworks ensures transparency and stakeholder engagement.
                        </p><p>
                        <b>Monitoring and Evaluation:</b> Defining KPIs and implementing a feedback loop for continuous improvement are essential for project success.
                        </p><p>
                        <b>Immediate Actions:</b> Finalizing stakeholder engagement and conducting risk assessments are immediate priorities.
                        </p><p>
                        <b>Short-Term and Long-Term Goals:</b> Short-term goals include installing the reactor and column, while long-term objectives focus on achieving sustainability targets and monitoring performance.
                        </p>

                        <br/>


                        {/* <div className="summary-card-image-row">
                            <img src="./product_images/round_1-image-1.jpg"></img>
                            <img src="./product_images/round_2-image-2.png"></img>                          
                            <img src="./product_images/round_1-image-2.png"></img>
                            <img src="./product_images/round_3-image-1.jpg"></img>
                        </div> */}
                    </div>
                    <div className="summary-buttons-container">
                        <button onClick={downloadReport}>Download report</button>
                        <button onClick={downloadTranscript}>Download full transcript</button>

                    </div>

                    {documentPath && <ReportPDF documentPath={documentPath} setDocumentPath={setDocumentPath} />}
                </div>
                )}
            </div>


        </>
    )
}

export default Summary;