import React, { useEffect, useState } from "react";
import chatWithUserAgent from '../../static/chatWithUserAgent.json'
import axios from "axios";
import MessageList from "../MessageList/MessageList";
import sales_agent_logo from "../../static/groupsales_agent.png"
import human_user_logo from "../../static/human_user.png"
import Modal from "../Modal/Modal";
import LoadingDots from "../Loading/LoadingDots";
import Agents from "./Agents";
import AgentsChat from "../AgentsChat/AgentsChat";
import image2 from "../../static/image_2.png"
import pdf from "../../static/pdf.png"



function Chat({ leftPannelContent, setLeftPannelContent }) {

    const [messages, setMessages] = useState([chatWithUserAgent["chatWithUserAgent"][0]])
    const [userInput, setUserInput] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isFinalMessageVisible, setIsFinalMessageVisible] = useState(false)
    const [isAgentsChatVisible, setIsAgentsChatVisible] = useState(false)

    useEffect(() => {
        document.getElementById('messages-container').lastChild.scrollIntoView({ block: "end" })
    })

    const handleButtonClick = () => {
        setUserInput('I need help developing a strategy and action plan for the implementation of a refinery expansion project');

    };
    const handleButtonClick2 = () => {
        setUserInput('I need help optimizing the marketing campaign for the new Eco Fuel');


    };

    const handleSendButton = () => {
        // Check if the input is not empty before adding to messages
        if (userInput.trim()) {
            // Create a new message object
            const newMessage = {
                role: "user", // Simple ID generation
                message: userInput,
            };

            setUserInput('')
            setMessages([...messages, newMessage]);
            setIsLoading(true)
            setLeftPannelContent((leftPannelContent) => [
                ["Implementation of a refinery expansion project",],
                ...leftPannelContent.slice(1)
            ])
            // document.getElementById('left-pannel').style.visibility = 'visible'
            document.getElementById('left-pannel').classList.toggle('left-pannel-slide')
            document.getElementById('previous-project-container').style.visibility = 'hidden'
            // document.getElementById('previous-project-container-side').style.visibility = 'hidden'
            document.getElementById('animation-gif').style.display = 'none'
            // document.getElementById('animation-gif').classList.toggle("#animation-gif:after");



            setTimeout(() => {
                const newMessage = {
                    role: "assistant", // Simple ID generation
                    message: 'Sure. Could you upload any useful information about the expansion, the objectives and your positioning?',
                };

                setMessages((prevMessages) => [...prevMessages, newMessage]);
                setIsLoading(false)


            }, 2000);


        }


    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleStartButton = () => {
        setIsFinalMessageVisible(true)
        setIsLoading(true)
        setTimeout(() => {
            setIsAgentsChatVisible(true)
            setIsLoading(false)

        }, 2000)


    };



    return (
        <>
            {/* <div className="previous-project-container-side" id="previous-project-container">
                    <p className="previous-project">Previous project</p>
                    <button className="product-acceptance-button" onClick={handleButtonClick2}>Optimize campaigns for a beauty product</button>
                    <button className="product-acceptance-button" onClick={handleButtonClick}>Market entry of a new product</button>
                </div> */}
            <div className="chat" id="chat">
                <img src="./loading_animation/agent orchestra_1.gif" id="animation-gif" />

                <div className="messages-container" id="messages-container">

                    {messages.map(message => {
                        {
                            if (message.role == "assistant") return (
                                <div className="message">
                                    <div className="message-content-assistant">
                                        {message.message}
                                    </div>
                                </div>
                            )
                        }
                        {
                            if (message.role == "user") return (
                                <div className="message message-with-icon">

                                    <div className="message-content-user">
                                        {message.message}
                                    </div>
                                    <img src={human_user_logo}></img>
                                </div>
                            )
                        }
                    }
                    )}

                    
                    {leftPannelContent[1] && (
                        <>
                            <div className="message message-with-icon">
                                <div className="message-content-user">

                                    <div className="image-row-user-message">
                                        {/* <div className="image-with-title-message">
                                            <img src='./product_images/input-image.png'></img>
                                            <p>ElectricTropix_Packaging.png</p>
                                        </div> */}
                                        <div className="image-with-title-message">
                                            <img src={pdf}></img>
                                            <p>Schwechat Refinery Brief.pdf</p>
                                        </div>
                                    </div>
                                </div>
                                <img src={human_user_logo}></img>
                            </div>
                            <Agents leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} setIsLoading={setIsLoading} isLoading={isLoading}/>
                        </>
                    )}


                    {leftPannelContent[2] && (
                        <div>

                            <div className="message">Noted. <br />Would you like to participate in the conversation?</div>
                            <button className="start-agent-conversation-button">Yes</button>
                            <button className="start-agent-conversation-button" onClick={handleStartButton}>No</button>
                            {isFinalMessageVisible && (
                                <div className="message" >Alright! I will brief the team members, you can sit back and watch our chat here.</div>
                            )
                            }
                            {isAgentsChatVisible && (
                                <AgentsChat />
                            )
                            }
                        </div>
                    )}
                    {isLoading && (<LoadingDots />)}
                </div>

                <div className="user-input-container">
                    <input
                        className="user-input"
                        type="text"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)} >

                    </input>
                    <button className="upload-button" onClick={openModal}>
                    
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.3753 8.37435H23.2503V8.49935V24.791C23.2503 27.8528 20.7705 30.3327 17.7087 30.3327C14.6469 30.3327 12.167 27.8528 12.167 24.791V7.08268C12.167 5.19672 13.6977 3.66602 15.5837 3.66602C17.4696 3.66602 19.0003 5.19672 19.0003 7.08268V21.9577C19.0003 22.6678 18.4188 23.2493 17.7087 23.2493C16.9985 23.2493 16.417 22.6678 16.417 21.9577V8.49935V8.37435H16.292H14.167H14.042V8.49935V21.9577C14.042 23.9817 15.6846 25.6243 17.7087 25.6243C19.7327 25.6243 21.3753 23.9817 21.3753 21.9577V7.08268C21.3753 3.88281 18.7835 1.29102 15.5837 1.29102C12.3838 1.29102 9.79199 3.88281 9.79199 7.08268V24.791C9.79199 29.1667 13.333 32.7077 17.7087 32.7077C22.0844 32.7077 25.6253 29.1667 25.6253 24.791V8.49935V8.37435H25.5003H23.3753Z" fill="white" stroke="white" stroke-width="0.25"/>
                    </svg>
                    </button>
                    <button className="send-button" onClick={handleSendButton}>
                    
                    <svg width="34" height="29" viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 29V0L34 14.5L0 29ZM3.57895 23.5625L24.7842 14.5L3.57895 5.4375V11.7812L14.3158 14.5L3.57895 17.2188V23.5625Z" fill="white"/>
                    </svg>

                    </button>
                </div>
                
                <div className="previous-project-container" id="previous-project-container">
                    <p className="previous-project">Previous project</p>
                    <button className="product-acceptance-button" onClick={handleButtonClick2}>Optimize campaign for a new Eco Fuel</button>
                    <button className="product-acceptance-button" onClick={handleButtonClick}>Strategy and action plan for refinery expansion project</button>
                </div>
                
            </div>

            {isModalOpen && <Modal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} setIsLoading={setIsLoading} />}

        </>
    )
}


export default Chat;