import React from 'react';
import LeftPannelAgent from './LeftPanelAgent';


const LeftPannelAgents = () => {


    return (
        <div className='left-pannel-team-container'>
            <LeftPannelAgent agentName = "EcoStrategist" />
            <LeftPannelAgent agentName = "ProjectMaster" />
            <LeftPannelAgent agentName = "OpsPro" />
            <LeftPannelAgent agentName = "AssetGuardian" />
            <LeftPannelAgent agentName = "SafetyShield" />
            <LeftPannelAgent agentName = "Surfer" />
            <LeftPannelAgent agentName = "FinWizard" />
            
            

        </div>
    );
};

export default LeftPannelAgents;